var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.partnerData)?_c('div',{staticClass:"relative group cursor-pointer",class:[
    _vm.getHeightOfScreenWidth(0.75),
    _vm.isModal?'lg:h-post-img lg:max-h-post-img':'lg:h-post-card-img lg:max-h-post-card-img'
  ],style:([{
    background: 'url(' + _vm.partnerData.image + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }]),on:{"click":function($event){return _vm.$emit('partner-clicked', _vm.partnerData.slug)}}},[_c('div',{staticClass:"flex flex-col justify-end h-full leading-tight"},[_c('div',{staticClass:"space-y-2"},[_c('div',{staticClass:"w-full flex flex-col px-3 pt-3 pb-2 bg-white bg-opacity-95",attrs:{"clase":_vm.isModal?'lg:px-3':'lg:px-6'}},[_c('div',{staticClass:"text-black font-semibold truncate text-base"},[_vm._v(" "+_vm._s(_vm.partnerData.name)+" ")]),_c('hr',{staticClass:"border-grayed border-dashed -mx-3 lg:-mx-6 my-2"}),_c('div',{staticClass:"flex flex-row justify-end text-xs"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.partnerData.summary)}})])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }