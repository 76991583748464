<template>
  <VTooltip v-show="url" class="flex flex-row items-center border-none">
    <slot>
      <a :href="url" target="_blank" class="underline hover:opacity-80 hover:text-redAccent">
        <base-icon height="24" width="24" :name=iconName />
      </a>
    </slot>
    <template #popper>
      {{ trans(tooltip) }}
    </template>
  </VTooltip>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon";

export default {
  name: "PartnerSocialIcon",
  components: {
    BaseIcon,
  },
  props: {
    url: String,
    iconName: String,
    tooltip: String,
  },
}
</script>