<template>
  <div v-if="partnerData"
    class="relative group cursor-pointer"
    :class="[
      getHeightOfScreenWidth(0.75),
      isModal?'lg:h-post-img lg:max-h-post-img':'lg:h-post-card-img lg:max-h-post-card-img'
    ]"
    @click="$emit('partner-clicked', partnerData.slug)"
    :style="[{
      background: 'url(' + partnerData.image + ')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }]"
  >
    <div class="flex flex-col justify-end h-full leading-tight">
      <div class="space-y-2">
        <div class="w-full flex flex-col px-3 pt-3 pb-2 bg-white bg-opacity-95"
          :clase="isModal?'lg:px-3':'lg:px-6'"
        >
          <div class="text-black font-semibold truncate text-base">
            {{ partnerData.name }}
          </div>
          <hr class="border-grayed border-dashed -mx-3 lg:-mx-6 my-2"/>
          <div class="flex flex-row justify-end text-xs">
            <span v-html="partnerData.summary"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon.vue";
import router from "@/router";
import Button from "@/utils/crud/components/Button.vue";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import {getWidth, getHeightOfScreenWidth, excerpt} from "@/common/helpers/utils";
import PictureLoader from "@/views/PictureLoader.vue";

export default {
  name: "PartnerCard",
  components: {PictureLoader, Button, BaseIcon},
  data() {
    return {
      isMobile: checkIsMobileDevice(),
      getHeightOfScreenWidth: getHeightOfScreenWidth,
      getWidth: getWidth,
      narrow: Boolean,
      small: Boolean,
    }
  },
  props: {
    isModal: Boolean,
    partnerData: Object,
    index: Number,
  },
  methods: {
    excerpt
  },
}
</script>