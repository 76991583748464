<template>
  <PageContainer :is-page-opened-on-modal="isModal">
    <PageTitle :is-page-opened-on-modal="isModal">
      <span>
        {{ trans('PAGE_TITLE:Partners') }}
      </span>
    </PageTitle>

    <div class="w-full flex flex-col lg:gap-6 lg:flex-row items-start">
      <div v-if="!isModal && !isMobile" class="w-full lg:w-sidebar">
        <Loader v-if="postCategoriesDataLoading" class="h-sidebar"/>
        <t-card v-else class="w-full">
          <PostsNavigation/>
        </t-card>
      </div>
      <div class="w-full"
        :class="{'lg:w-main-container':!isModal}"
      >
        <div class="w-full flex flex-col lg:justify-center">
          <Loader v-if="partnersLoading"
            :no-border="isModal"
            class="w-full lg:h-sidebar"
            :class="[getHeightOfScreenWidth(1,isModal?48:24), {'rounded-lg':isModal}]"
          />
          <div v-else class="grid lg:grid-cols-2 gap-3 lg:gap-4 w-full">
            <div class="overflow-hidden row-span-1 lg:col-span-1" v-for="(partner, index) in partnersData" :key="partner.id">
              <div class="lg:rounded-lg overflow-hidden  lg:border border-t border-b border-grayed">
                <PartnerCard @partner-clicked="showPartner" :is-modal="isModal" :small="true" :partner-data="partner" />
              </div>
            </div>
          </div>
          <div class="mt-3" :class="{'hidden': partnersLoading}"
            v-if="partnersData.length > 0 && pagination.total > 6"
          >
            <div class="flex-1 flex items-center justify-end">
              <t-pagination
                variant="partners"
                :total-items="pagination.total"
                :per-page="pagination.perPage"
                :limit="6"
                v-model="currentPage"
                @change="next"
                :disabled="partnersLoading"
              />
            </div>
          </div>
          <Modal hide-footer variant="post" v-if="isShowProfileModal" :show-modal="isShowProfileModal" @clicked="isShowProfileModal = false">
            <template #content>
              <PartnerShow :slug="chosenPartnerSlug" :partnerData="getPartnerBySlug(chosenPartnerSlug)" />
            </template>
          </Modal>
        </div>
      </div>
    </div>
  </PageContainer>
</template>

<script>
import Modal from "@/utils/modal/Modal.vue";
import PartnerCard from "@/views/content/partners/components/PartnerCard.vue";
import Loader from "@/views/Loader.vue";
import PartnerShow from "@/views/content/partners/PartnerShow.vue";
import {getHeightOfScreenWidth} from "@/common/helpers/utils";
import {mapActions, mapGetters} from "vuex";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import PageTitle from "@/layouts/components/pages/PageTitle";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PostsNavigation from "@/views/content/posts/components/PostsNavigation.vue";

export default {
	name: "Partners",
	components: {
		PartnerShow,
		Modal,
		Loader,
		PartnerCard,
    PageTitle,
    PageContainer,
    PostsNavigation,
	},
	props: {
		isModal:false,
	},
	data() {
		return {
			getHeightOfScreenWidth: getHeightOfScreenWidth,
			isShowProfileModal: false,
			partnerToShow: {},
			chosenPartnerSlug: null,
		}
	},
	computed: {
    ...mapGetters('dictionaries', ['postCategoriesData', 'postCategoriesDataLoading']),
		...mapGetters('partners', ['partnersData', 'pagination', 'partnersLoading']),

    isMobile() {
      return checkIsMobileDevice();
    }
  },
	methods: {
		...mapActions('partners', ['fetchPartnersData']),

		showPartner(slug) {
			this.isShowProfileModal = true;
			this.chosenPartnerSlug = slug
			if (!this.isModal) {
				this.$router.push({name: 'partners-show', params: {slug: slug}})
			}
		},

		getPartnerBySlug(slug) {
			let selectedPartner = null;
			this.partnersData.forEach((partner) => {
				if(partner.slug === slug) {
					selectedPartner = partner
				}
			});

			return selectedPartner;
		}
	},
	mounted() {
		this.fetchPartnersData();

		if( this.$route.params.slug) {
			this.showPartner(this.isModal ? this.chosenPartnerSlug : this.$route.params.slug)
		}
	}
}
</script>
