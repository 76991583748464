<template>
  <div id="tooltip-default"
       role="tooltip"
       :class="{'opacity-1': showPopover}"
       class="inline-block absolute py-2 px-3 text-xs text-white bg-black rounded shadow-sm opacity-0 transition-opacity duration-200 tooltip"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "Popover",
  props: {
    text: String,
    showPopover: Boolean,
  },
  mounted() {}
}
</script>
